import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { BackgroundImage } from 'components/BackgroundImage'
import Logo from 'components/Logo'
import { Button } from 'components/Button'
import { themes } from '../../global'
import './AgePrompt.scss'

const AgePrompt = ({ userOfAge, setUserOfAge }) => {
  const colorTheme = themes.black
  const { backgroundImage } = useStaticQuery(graphql`
    query modalImages {
      backgroundImage: file(
        relativePath: { eq: "oregano-leaf-background.jpg" }
      ) {
        id
        relativePath
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `)

  return (
    <div
      id='age-prompt-container'
      className='animation-age-gate'
      style={{ backgroundColor: colorTheme.backgroundColor, opacity: 0.82 }}>
      <BackgroundImage
        id='age-prompt-background'
        image={backgroundImage.childImageSharp.gatsbyImageData}
        backgroundColor={colorTheme.backgroundColor}>
        <div
          className='overlay'
          style={{
            backgroundColor: colorTheme.backgroundColor,
          }}
        />
        <div
          className='contentWrapper'
          style={{
            color: colorTheme.bodyColor,
          }}>
          <div className='iliad__popup--logo-container'>
            <Logo theme='dark' />
          </div>
          <h3>Are you 21 years or older?</h3>
          <p>
            If you have not had 21 birthdays, please exit now and come back to
            visit us then. Thank you!
          </p>
          <Button
            buttonText="Yes, I'm 21 or older"
            onClick={setUserOfAge}
            colorTheme={colorTheme}
          />
        </div>
      </BackgroundImage>
    </div>
  )
}

export default AgePrompt
